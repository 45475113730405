import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"

import SingleProcedureHero from "../components/SingleProcedureHero"
import { Text, Image } from "../components/Core"
import { Section, Columns } from "../components/Containers"
import Layout from "../components/layout"
import { Heading } from "../components/Hero"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import { CallToAction } from "../components/CTA"
import SEO from "../components/seo"

const FirstVisitPage = ({ data, location, pageContext }) => {
  const post = data.allUniquePagesJson.nodes[0]
  return (
    <Layout
      language={pageContext.language}
      pageTitle="first-visit"
      pageHeading={post.heading}
      footerColorBack>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={pageContext.language}
      />

      {!post.useAnimatedHero && (
        <Heading className="mb-0" heading={post.heading}>
          {/* <Columns sideColumnsSize={2}>
            <div className="column mt-2">
              <Image
                wrapperClassName="is-hidden-touch"
                publicId={post.heroImage}
              />
              <div
                className="is-hidden-desktop"
                style={{ marginLeft: "-40px", marginRight: "-40px" }}>
                <Image publicId={post.heroImageMobile} />
              </div>
            </div>
          </Columns> */}
        </Heading>
      )}

      {post.useAnimatedHero && (
        <SingleProcedureHero
          hero={post.hero}
          heading={post.heading}
          language={pageContext.language}
          youtube={post.youtube}
        />
      )}

      <div className="angled-container color-back pb-4">
        <Section zeroTop>
          <Columns sideColumnsSize={3}>
            <div className="column px-40--mobile">
              {post.sections.map((sect, i) => (
                <div
                  className={`first-visit__section ${
                    sect.image ? "has-image" : ""
                  } ${sect.colReverse ? "reverse" : ""}`}
                  key={i}>
                  <div className="first-visit__content">
                    <Text useStringReplace text={sect.text} />

                    {sect.list.listItems && sect.list.listItems.length >= 1 && (
                      <ul
                        className={`first-visit__list first-visit__list--${
                          sect.list.isHorizontal & !sect.image
                            ? "horizontal"
                            : "vertical"
                        }`}>
                        {sect.list.listItems.map((item, i) => (
                          <li key={i}>
                            <FontAwesomeIcon icon={faArrowRight} />
                            <Text as="span" text={item} />
                          </li>
                        ))}
                      </ul>
                    )}

                    {sect.buttons && sect.buttons.length >= 1 && (
                      <ButtonGroupMap buttons={sect.buttons} />
                    )}
                  </div>
                  {sect.image && <Image publicId={sect.image} />}
                </div>
              ))}
            </div>
          </Columns>
        </Section>
      </div>

      <CallToAction
        // colorBack
        heading={post.callToAction.heading}
        blurb={post.callToAction.blurb}
        buttons={post.callToAction.buttons}
      />
    </Layout>
  )
}

export default FirstVisitPage

export const pageQuery = graphql`
  query yourFirstVisitPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        useAnimatedHero
        heroImage
        heroImageMobile
        hero {
          image
          imageMobile
          animationStyle
          position
          size
          className
        }
        heading
        blurb
        metaTitle
        metaDescription
        sections {
          text
          list {
            isHorizontal
            # listItems
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          image
          colReverse
        }

        callToAction {
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
      }
    }
  }
`
